<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <dispatch-form
      v-if="dispatch.id"
      ref="dispatch-form"
      :is-edit="isEdit"
    />
    <div v-else>
      <div v-if="!error">
        <skeleton />
      </div>
      <div v-else>
        <component-not-found
          title="Dispatch List"
          :button-link="{ name: 'settings-fulfillment-dispatch-list'}"
        />
      </div>
    </div>
  </div>

</template>
<script>
import { BCol, BRow, VBTooltip } from 'bootstrap-vue'
import store from '@/store'
import Skeleton from '../components/Skeleton.vue'
import config from '../config'
import DispatchForm from '../components/DispatchForm.vue'

export default {
  name: 'DispatchUpdate',
  components: {
    BRow,
    BCol,
    Skeleton,
    DispatchForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['dispatch-form']) this.$refs['dispatch-form'].showConfirmationModal(next)
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    dispatch() {
      return this.$store.state[this.MODULE_NAME].dispatchForm
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const dispatch = this.mappingFields(Object.keys(this.dispatchFields), data, {
        id: data.id,
        name: data.name,
        is_active: data.is_active,
        is_courier: data.is_courier,
        requires_shipping_contact_id: data.requires_shipping_contact_id,
        ecommerce_shipping_method_id: data.ecommerce_shipping_method,
        payment_terms: data.payment_terms_is_checked.map(({ payment_term_id, is_checked }) => ({
          id: payment_term_id,
          is_checked: `${is_checked}`,
        })),
        payment_options: data.payment_options_is_checked.map(({ payment_option_id, is_checked }) => ({
          id: payment_option_id,
          is_checked: `${is_checked}`,
        })),
        linked_supplier_account_id: data.linked_supplier_account_id,
        linked_supplier_account: data.linked_supplier_account,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, dispatch)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_DISPATCH_FORM_CLONE`, dispatch)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE, dispatchFields } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      dispatchFields,
    }
  },
}
</script>
